export var urls;
(function (urls) {
    let Booking;
    (function (Booking) {
        Booking.CreateDispatch = (vehicleId) => `/public/booking/vehicles/${vehicleId}/dispatch`;
        Booking.CreateRide = (vehicleId) => `/public/booking/vehicles/${vehicleId}/rides`;
        Booking.GetVehiclePassengerDetails = (vehicleId) => `/public/booking/vehicles/${vehicleId}/passenger_details`;
        Booking.GetAvStackRoutes = (vehicleId) => `/public/booking/vehicles/${vehicleId}/av-stack-routes`;
        Booking.DispatchOnAvStackRoute = (vehicleId) => `/public/booking/vehicles/${vehicleId}/dispatch-on-av-stack-route`;
        Booking.GetRouteEnginePudos = (vehicleId) => `/public/booking/vehicles/${vehicleId}/route-engine-pudos`;
        Booking.GetRouteEngineStreetSegments = (vehicleId) => `/public/booking/vehicles/${vehicleId}/route-engine-street-segments`;
        Booking.GetRouteEngineRoutes = (vehicleId) => `/public/booking/vehicles/${vehicleId}/route-engine-routes`;
        Booking.DispatchOnRouteEngineRoute = (vehicleId) => `/public/booking/vehicles/${vehicleId}/dispatch-on-route-engine-route`;
        Booking.GetRouteEnginePackages = (vehicleId) => `/public/booking/vehicles/${vehicleId}/route-engine-packages`;
        Booking.DispatchOnRouteEnginePackage = (vehicleId) => `/public/booking/vehicles/${vehicleId}/dispatch-on-route-engine-package`;
    })(Booking = urls.Booking || (urls.Booking = {}));
    let Operators;
    (function (Operators) {
        Operators.PartnersDetail = (operatorEmail) => `/public/operators/${operatorEmail}/partners`;
        Operators.PartnersUpdate = (operatorEmail, partnerName) => `/public/operators/${operatorEmail}/partners/${partnerName}`;
        Operators.PartnersDelete = (operatorEmail, partnerName) => `/public/operators/${operatorEmail}/partners/${partnerName}`;
        Operators.PartnersList = () => `/public/operators/partners`;
    })(Operators = urls.Operators || (urls.Operators = {}));
    let Vehicles;
    (function (Vehicles) {
        Vehicles.PartnersDetail = (vehicleVin) => `/public/vehicles/${vehicleVin}/partners`;
        Vehicles.PartnersUpdate = (vehicleVin, partnerName) => `/public/vehicles/${vehicleVin}/partners/${partnerName}`;
        Vehicles.PartnersDelete = (vehicleVin, partnerName) => `/public/vehicles/${vehicleVin}/partners/${partnerName}`;
        Vehicles.PartnersList = () => `/public/vehicles/partners`;
        Vehicles.VehiclesPartialUpdate = (vehicleVin) => `/public/vehicles/${vehicleVin}`;
    })(Vehicles = urls.Vehicles || (urls.Vehicles = {}));
    let RideHistory;
    (function (RideHistory) {
        RideHistory.RideHistoryList = () => `/public/ride-history`;
    })(RideHistory = urls.RideHistory || (urls.RideHistory = {}));
    let VehicleSettings;
    (function (VehicleSettings) {
        VehicleSettings.Manifest = () => `/public/vehicle-settings/manifest`;
        VehicleSettings.GetPublicBehaviors = () => `/public/vehicle-settings/behaviors`;
        VehicleSettings.PostPublicBehaviors = () => `/public/vehicle-settings/behaviors`;
        VehicleSettings.PutPublicBehaviorsBehaviorId = (behaviorId) => `/public/vehicle-settings/behaviors/${behaviorId}`;
        VehicleSettings.DeletePublicBehaviorsBehaviorId = (behaviorId) => `/public/vehicle-settings/behaviors/${behaviorId}`;
        VehicleSettings.GetPublicBehaviorsBehaviorId = (behaviorId) => `/public/vehicle-settings/behaviors/${behaviorId}`;
        VehicleSettings.PutPublicVehicleOverridesVehicleId = (vehicleId) => `/public/vehicle-settings/vehicle-overrides/${vehicleId}`;
        VehicleSettings.GetPublicVehicleOverridesVehicleId = (vehicleId) => `/public/vehicle-settings/vehicle-overrides/${vehicleId}`;
        VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId = (vehicleId) => `/public/vehicle-settings/vehicle-summaries/${vehicleId}`;
    })(VehicleSettings = urls.VehicleSettings || (urls.VehicleSettings = {}));
    let Pudos;
    (function (Pudos) {
        Pudos.GetPudos = () => `/public/pudos`;
        Pudos.PostPudo = () => `/public/pudos`;
        Pudos.GetPudosGroups = () => `/public/pudos/groups`;
        Pudos.GetPudoById = (pudoId) => `/public/pudos/${pudoId}`;
        Pudos.PutPudo = (pudoId) => `/public/pudos/${pudoId}`;
        Pudos.DeletePudo = (pudoId) => `/public/pudos/${pudoId}`;
    })(Pudos = urls.Pudos || (urls.Pudos = {}));
    let ExecutionPlans;
    (function (ExecutionPlans) {
        ExecutionPlans.CreateExecutionPlan = (vehicleId) => `/public/execution-plans/vehicle/${vehicleId}`;
        ExecutionPlans.GetExecutionPlan = (vehicleId) => `/public/execution-plans/vehicle/${vehicleId}`;
        ExecutionPlans.CancelExecutionPlan = (vehicleId) => `/public/execution-plans/vehicle/${vehicleId}/cancel`;
    })(ExecutionPlans = urls.ExecutionPlans || (urls.ExecutionPlans = {}));
    let Geonets;
    (function (Geonets) {
        Geonets.CreateGeonetConstraint = () => `/public/geonets/constraints`;
        Geonets.GetGeonetConstraints = () => `/public/geonets/constraints`;
        Geonets.GetGeonetConstraintByName = (name) => `/public/geonets/constraints/${name}`;
        Geonets.UpdateGeonetConstraint = (name) => `/public/geonets/constraints/${name}`;
        Geonets.DeleteGeonetConstraint = (name) => `/public/geonets/constraints/${name}`;
        Geonets.GetGeonetSessions = () => `/public/geonets/sessions`;
        Geonets.CreateGeonetSessions = () => `/public/geonets/sessions`;
        Geonets.UpdateGeonetSessions = (id) => `/public/geonets/sessions/${id}`;
        Geonets.DeleteGeonetSessions = (id) => `/public/geonets/sessions/${id}`;
    })(Geonets = urls.Geonets || (urls.Geonets = {}));
})(urls || (urls = {}));
