import t from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import Icon from 'src/components/common/Icon';
import { Message as MessageType } from 'src/components/Messages/messages-context';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { useInterval } from 'src/tools/hooks/useInterval';
import { useUserIsActive } from 'src/tools/hooks/userIsActive';
import { useTimeout } from 'src/tools/hooks/useTimeout';
import Message from './Message';

// the real max timout is 2**31, but going over makes the value 1
const INFINITE_TIMEOUT = 2 ** 30;
const DISMISS_TIMEOUT = inMilliseconds(10, 'seconds');
const INACTIVE_TIMEOUT = inMilliseconds(15, 'seconds');

type Props = {
  message: MessageType;
  onDismiss?: (messageId: string) => void;
  className?: string;
};

function ToastMessage({ message, onDismiss, className }: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [periodDone, setPeriodDone] = useState<number>();
  const userIsActive = useUserIsActive(INACTIVE_TIMEOUT);

  const { startedAt } = useTimeout(
    () => {
      onDismiss?.(message.id);
    },

    !userIsActive || isFocused ? INFINITE_TIMEOUT : DISMISS_TIMEOUT,
  );

  const interval = useCallback(() => {
    if (!startedAt || !userIsActive || isFocused) {
      setPeriodDone(undefined);
      return;
    }
    // 0.15 is added to make the countdown animation reach early rather than late
    setPeriodDone(
      Math.round(
        Math.max(0, Math.min(1, (Date.now() - startedAt) / DISMISS_TIMEOUT)) *
          100,
      ) /
        100 +
        0.1,
    );
  }, [isFocused, startedAt, userIsActive]);
  useInterval(interval, 500);

  const handleDismissClick = () => {
    onDismiss?.(message.id);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleUnfocus = () => {
    setIsFocused(false);
  };

  const customProperties = useMemo(() => {
    const customProperties: Parameters<typeof Message>[0]['customProperties'] =
      {};

    if (typeof periodDone === 'number') {
      customProperties['--period-done'] = periodDone;
    }

    return customProperties;
  }, [periodDone]);

  return (
    <Message
      description={message.description}
      errorMessage={message.errorMessage}
      title={message.title}
      type={message.type}
      onFocus={handleFocus}
      onUnfocus={handleUnfocus}
      customProperties={customProperties}
      className={clsx([
        className,
        { 'the-message--is-idle': !userIsActive },
        { 'the-message--is-dismissible': !!onDismiss },
      ])}
      messageSuffix={
        onDismiss && (
          <button
            type="button"
            className="the-message__dismiss"
            onClick={handleDismissClick}
            aria-label={t('messages.dismiss')}
          >
            <Icon name="MultiplyFat" />
          </button>
        )
      }
    />
  );
}

export default ToastMessage;
