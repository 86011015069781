import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon from 'src/components/common/Icon';
import { Message as MessageType } from 'src/components/Messages/messages-context';
import './Message.scss';

const iconMessageMap = {
  success: <Icon name="CircleWithTick" className="the-message__icon" />,
  warning: (
    <Icon name="TriangleWithExclamation" className="the-message__icon" />
  ),
  error: <Icon name="DiamondWithExclamation" className="the-message__icon" />,
  info: <Icon name="CircleWithLetterI" className="the-message__icon" />,
};

type Props = {
  className?: string;
  messageSuffix?: ReactNode;
  onFocus?: () => void;
  onUnfocus?: () => void;
  customProperties?: { [PropertyName: `--${string}`]: string | number };
} & Omit<MessageType, 'id' | 'overridable'>;

function Message({
  className,
  messageSuffix,
  onFocus,
  onUnfocus,
  customProperties,
  // props from MessageType
  description,
  errorMessage,
  title,
  type,
}: Props) {
  return (
    <div
      role="status"
      onMouseEnter={onFocus}
      onMouseLeave={onUnfocus}
      onFocus={onFocus}
      onBlur={onUnfocus}
      style={customProperties}
      className={clsx([
        'the-message',
        className,
        { [`the-message--${type}`]: type },
      ])}
    >
      <div className="the-message__mark">{iconMessageMap[type]}</div>

      {(title || description) && (
        <hgroup className="the-message__content">
          {title && <h3 className="the-message__title">{title}</h3>}

          {!description ?
            null
          : Array.isArray(description) ?
            description.map((description, index) => (
              <p key={index} className="the-message__description">
                {description}
              </p>
            ))
          : <p className="the-message__description">{description}</p>}
        </hgroup>
      )}

      {errorMessage &&
        (!Array.isArray(errorMessage) || errorMessage?.length > 0) && (
          <details className="the-message__error-message">
            <summary>See error message</summary>
            {Array.isArray(errorMessage) ?
              errorMessage.map((errorMessage, index) => (
                <p key={`${errorMessage}-${index}`}>{errorMessage}</p>
              ))
            : <p>{errorMessage}</p>}
          </details>
        )}

      {messageSuffix}
    </div>
  );
}

export default Message;
