import type { DataLoadStatus } from 'src/interface/command-center/unsorted-types';
import { FleetVehicle } from '@motional-cc/fe/interface/api/armada';
import t from '@motional-cc/fe/tools/translate';
import compact from 'lodash/compact';
import find from 'lodash/find';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { userApi } from 'src/api/user';
import EmptyState from 'src/components/common/EmptyState';
import Icon from 'src/components/common/Icon';
import ActionCell from 'src/components/common/Table/ActionCell';
import {
  ColumnDefinition,
  TableSort,
} from 'src/components/common/Table/interface';
import Table from 'src/components/common/Table/Table';
import {
  DEFAULT_PICKED_COLUMNS,
  useAvailableColumnKeys,
  useAvailableColumns,
} from 'src/components/FleetManagement/Jobs/VehicleRegistrationColumnPicker';
import useModal from 'src/components/Modal/modal-hook';
import VehicleManagement, {
  View,
} from 'src/components/VehicleRegistration/VehicleManagement';
import { useUserProfileSetting } from 'src/contexts/profile-settings-context';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import useOnChange from 'src/tools/hooks/useOnChange';

interface Props {
  sort?: TableSort;
  vehicles?: FleetVehicle[];
  vehiclesAreLoading?: boolean;
  className?: string;
  header?: ReactNode;
  error?: ApiError;
  status?: DataLoadStatus;
  searchTerm?: string;
  canFetchMoreVehicles?: boolean;
  fetchMoreVehicles?: () => void;
  onSort?: (newSort: TableSort) => void;
  onVehicleUpdate?: () => void;
}

function VehicleList({
  sort,
  vehicles = [],
  vehiclesAreLoading,
  className,
  header,
  error,
  status = 'pending',
  searchTerm,
  canFetchMoreVehicles,
  fetchMoreVehicles,
  onSort,
  onVehicleUpdate,
}: Props) {
  const hasEditVehicleScopes = userApi.useHasScopes(['manage:provision']);
  const { setting: _pickedColumns } = useUserProfileSetting(
    'vehicle-registration-table-columns',
  );
  const pickedColumns = _pickedColumns || DEFAULT_PICKED_COLUMNS;

  const {
    openModal: openVehicleDetailsModal,
    closeModal: closeVehicleDetailsModal,
    ModalComponent: VehicleDetailsModal,
  } = useModal();

  const availableColumns = useAvailableColumns();
  const defaultColumnOrder = useAvailableColumnKeys();
  const { setting: columnOrderSetting } = useUserProfileSetting(
    'vehicle-registration-table-column-order',
  );
  const columnOrder = columnOrderSetting || defaultColumnOrder;

  const [loadingVisible, setLoadingVisible] = useState(true);
  const [vehicleManagementView, setVehicleManagementView] =
    useState<View>('viewVehicle');
  const [editingVehicleCarId, setEditingVehicleCarId] = useState<string>();
  const [editingVehicleVin, setEditingVehicleVin] = useState<string>();

  const baseColumns = useMemo(
    () =>
      !pickedColumns || !columnOrder ?
        []
      : compact(
          pickedColumns
            .filter((column) => defaultColumnOrder.includes(column))
            .sort(
              (columnA, columnB) =>
                columnOrder.indexOf(columnA) - columnOrder.indexOf(columnB),
            )
            .map((key) => {
              const columnDescriptor = find(availableColumns, ['key', key]);
              const column =
                columnDescriptor?.sortable ?
                  {
                    ...columnDescriptor,
                    onSort: (sort: TableSort) => {
                      setLoadingVisible(true);
                      onSort?.(sort);
                    },
                  }
                : { ...columnDescriptor };

              // TODO: explain why we delete sortable
              delete column.sortable;
              return column as ColumnDefinition;
            }),
        ),
    [defaultColumnOrder, availableColumns, columnOrder, onSort, pickedColumns],
  );
  const columns = useMemo<ColumnDefinition[]>(() => {
    if (!hasEditVehicleScopes) {
      return baseColumns;
    }

    return [
      ...baseColumns,
      {
        label: '',
        valuePath: '',
        CellComponent: ActionCell,
        width: 50,
        action: (vehicle: (typeof vehicles)[number]) => {
          setEditingVehicleCarId(vehicle.carId);
          setEditingVehicleVin(vehicle.vin);
          openVehicleDetailsModal();
        },
        actionIcon: <Icon name="Dots1x3" />,
        actionLabel: 'Edit vehicle',
        isLockedRight: true,
        justify: 'center',
      },
    ];
  }, [baseColumns, hasEditVehicleScopes, openVehicleDetailsModal]);

  useEffect(
    function loadingFinishedReset() {
      if (!vehiclesAreLoading && loadingVisible) {
        setLoadingVisible(false);
      }
    },
    [vehiclesAreLoading, loadingVisible],
  );

  useOnChange(
    searchTerm,
    useCallback((newValue: string) => {
      if (!newValue) return;
      setLoadingVisible(true);
    }, []),
  );

  const handleVehicleChange = () => {
    onVehicleUpdate?.();
    setVehicleManagementView('viewVehicle');
  };

  const handleCloseManagementViewClick = () => {
    handleVehicleChange();
    closeVehicleDetailsModal();
  };

  const isLoading = loadingVisible && isIncompleteStatus(status);

  return vehicles || isLoading ?
      <>
        <Table
          isFullWidthOnMobile
          className={className}
          sort={sort}
          columns={columns}
          data={vehicles}
          rowKey={({ carId, vin }) => `${vin}--${carId || 'no-id'}`}
          isFetching={isLoading}
          minTableWidth={`${Math.min(1050, (columns.length ?? 0) * 120)}px`}
          header={header}
          error={error}
          status={status}
          emptyTitle="No vehicles to show"
          emptyDetails="This is usually because the search term doesn’t match any vehicles."
          onScrollToBottom={
            canFetchMoreVehicles ? fetchMoreVehicles : undefined
          }
        />

        <VehicleDetailsModal
          title={t(`vehicleRegistration.${vehicleManagementView}.title`)}
          onClose={handleVehicleChange}
          strapline={t(
            `vehicleRegistration.${vehicleManagementView}.strapline`,
          )}
          onBackClick={
            (
              (!editingVehicleCarId && !editingVehicleVin) ||
              vehicleManagementView === 'viewVehicle'
            ) ?
              undefined
            : () => {
                setVehicleManagementView('viewVehicle');
              }
          }
        >
          {editingVehicleCarId || editingVehicleVin ?
            <VehicleManagement
              carId={editingVehicleCarId}
              vehicleVin={editingVehicleVin}
              view={vehicleManagementView}
              onChangeView={setVehicleManagementView}
              onClose={handleCloseManagementViewClick}
            />
          : <EmptyState title="No vehicle selected to edit" />}
        </VehicleDetailsModal>
      </>
    : <EmptyState title="Vehicles failed to load" />;
}

export default VehicleList;
