import clsx from 'clsx';
import {
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { DetailedInputError } from './Select/interface';
import './FieldWrapper.scss';

interface AdornmentProps {
  inputId: string;
  className?: string;
  disabled: boolean;
  position: 'start' | 'end';
  adornmentLabel?: string;
  icon?: ReactNode;
  onAdornmentClick?: () => void;
}

function Adornment({
  inputId,
  className,
  disabled,
  position,
  adornmentLabel,
  icon,
  onAdornmentClick,
}: AdornmentProps) {
  const handleAdornmentClick = () => {
    onAdornmentClick?.();
  };

  return (
    onAdornmentClick ?
      <button
        aria-label={adornmentLabel}
        type="button"
        disabled={disabled}
        onClick={handleAdornmentClick}
        className={clsx([
          className,
          'field-wrapper__adornment',
          'field-wrapper__adornment--role-action',
          `field-wrapper__adornment--position-${position}`,
        ])}
      >
        {icon && <span className="field-wrapper__icon">{icon}</span>}
      </button>
    : icon ?
      <label
        aria-label={adornmentLabel}
        htmlFor={inputId}
        className={clsx([
          className,
          'field-wrapper__adornment',
          'field-wrapper__adornment--role-presentation',
          `field-wrapper__adornment--position-${position}`,
        ])}
      >
        <span className="field-wrapper__icon">{icon}</span>
      </label>
    : null
  );
}

interface ChildProps {
  className: string;
  id: string;
  errorId: string | undefined;
  disabled: boolean;
  required: boolean;
  placeholder: string;
  label: string;
  hideLabel?: boolean;
}

interface PrivateProps {
  className?: string;
  children: (props: ChildProps) => ReactNode;
}

export interface PublicProps {
  inputId?: string;
  label: string;
  placeholder: string;
  errorMessage?: string | DetailedInputError;
  infoMessage?: string;
  showErrorMessage?: boolean;
  disabled?: boolean;
  isStatic?: boolean;
  required?: boolean;
  hideLabel?: boolean;

  renderStartIcon?: () => ReactNode;
  onStartAdornmentClick?: () => void;
  startAdornmentClassName?: string;
  startAdornmentLabel?: string;

  renderEndIcon?: () => ReactNode;
  onEndAdornmentClick?: () => void;
  endAdornmentClassName?: string;
  endAdornmentLabel?: string | undefined;

  secondaryActionLabel?: string;
  onSecondaryActionClick?: () => void;
}

function FieldWrapper(
  {
    inputId: propsInputId,
    className,
    label,
    placeholder,
    infoMessage,
    errorMessage,
    showErrorMessage: propsShowErrorMessage = true,
    disabled: disabledProps = false,
    isStatic = false,
    required = false,
    hideLabel = false,

    children: renderChild,

    renderStartIcon,
    onStartAdornmentClick,
    startAdornmentClassName,
    startAdornmentLabel,

    renderEndIcon,
    onEndAdornmentClick,
    endAdornmentClassName,
    endAdornmentLabel,

    secondaryActionLabel,
    onSecondaryActionClick,
  }: PrivateProps & PublicProps,
  ref: Ref<HTMLSpanElement>,
) {
  const disabled = disabledProps || isStatic;
  const showErrorMessage = propsShowErrorMessage && Boolean(errorMessage);
  const id = useId();
  const inputId = propsInputId ?? id;
  const errorId = useMemo(
    () => (showErrorMessage ? `${id}-error` : undefined),
    [showErrorMessage, id],
  );
  const fullLabel = `${label}${required ? ' - required' : ''}`;

  const [hadNoStartIcon, setHadNoStartIcon] = useState(false);
  const [hadNoEndIcon, setHadNoEndIcon] = useState(false);

  const inputElement = renderChild({
    disabled,
    required,
    placeholder,
    id: inputId,
    errorId,
    className: 'field-wrapper__input',
    hideLabel,
    label,
  });

  const handleSecondaryClick = () => {
    onSecondaryActionClick?.();
  };

  const startIcon = renderStartIcon?.();
  const endIcon = renderEndIcon?.();
  const hasStartIcon = Boolean(startIcon);
  const hasEndIcon = Boolean(endIcon);

  useEffect(
    function setStartIconWasMissing() {
      if (hasStartIcon) return;
      setHadNoStartIcon(true);
    },
    [hasStartIcon],
  );

  useEffect(
    function setEndIconWasMissing() {
      if (hasEndIcon) return;
      setHadNoEndIcon(true);
    },
    [hasEndIcon],
  );

  return (
    <div
      className={clsx([
        className,
        'field-wrapper',
        {
          'field-wrapper--is-showing-error': showErrorMessage,
          'field-wrapper--has-error': Boolean(errorMessage),
          'field-wrapper--is-disabled': disabled || isStatic,
          'field-wrapper--is-static': isStatic,
          'field-wrapper--is-required': required,
          'field-wrapper--label-hidden': hideLabel,
        },
      ])}
    >
      <span ref={ref} className="field-wrapper__border">
        {/* TODO: Remove this extra wrapper when Safari stops having the grid-border bug */}
        <span ref={ref} className="field-wrapper__content">
          <Adornment
            position="start"
            inputId={inputId}
            disabled={disabled}
            icon={startIcon}
            adornmentLabel={startAdornmentLabel}
            onAdornmentClick={onStartAdornmentClick}
            className={clsx([
              startAdornmentClassName,
              { 'field-wrapper__adornment--was-missing': hadNoStartIcon },
            ])}
          />

          {/* TODO: find a better way to deal with this */}
          <label
            htmlFor={inputId}
            className="field-wrapper__label"
            aria-label={hideLabel ? fullLabel : undefined}
          >
            {!hideLabel && (
              <>
                <span className="field-wrapper__label-text">
                  {label || '—'}
                </span>
                {required && (
                  <span
                    className="field-wrapper__required-asterisk"
                    aria-label=" - required"
                  >
                    *
                  </span>
                )}
              </>
            )}
          </label>

          <span className="field-wrapper__input-container">{inputElement}</span>

          <Adornment
            position="end"
            inputId={inputId}
            disabled={disabled}
            icon={endIcon}
            adornmentLabel={endAdornmentLabel}
            onAdornmentClick={onEndAdornmentClick}
            className={clsx([
              endAdornmentClassName,
              { 'field-wrapper__adornment--was-missing': hadNoEndIcon },
            ])}
          />
        </span>
      </span>

      {infoMessage ?
        <span className="field-wrapper__info" role="note">
          {infoMessage}
        </span>
      : null}

      {showErrorMessage ?
        <span className="field-wrapper__error">
          <span
            role="alert"
            id={errorId}
            aria-labelledby={errorId}
            className={clsx([
              'field-wrapper__error-text',
              `field-wrapper__error-text--level-${
                typeof errorMessage === 'string' || !errorMessage?.level ?
                  'error'
                : errorMessage?.level
              }`,
            ])}
          >
            {' '}
            {typeof errorMessage === 'string' ?
              errorMessage
            : errorMessage?.text}
          </span>
        </span>
      : null}

      {secondaryActionLabel && onSecondaryActionClick ?
        <button
          type="button"
          className="field-wrapper__secondary-action"
          onClick={handleSecondaryClick}
        >
          {secondaryActionLabel}
        </button>
      : null}
    </div>
  );
}

export default forwardRef(FieldWrapper);
