import t from '@motional-cc/fe/tools/translate';
import { armadaApi } from 'src/api/armada';
import { userApi } from 'src/api/user';
import FieldSetWithoutAccessibleLabel from 'src/components/common/FieldSetWithoutAccessibleLabel';
import IconButton from 'src/components/common/IconButton';
import LoadingErrorEmptyState from 'src/components/common/LoadingErrorEmptyState';
import TextField from 'src/components/common/TextField';
import { useHasToggle } from 'src/tools/hooks/useHasToggle';

interface Props {
  carId: string;
  onEditVehicleClick?: () => void;
}

function ViewVehicle({ carId, onEditVehicleClick }: Props) {
  const canManageVehicle = userApi.useHasScopes(['manage:remove_vehicle']);
  const wantsToManageSweeps = useHasToggle('ENABLE_GEONET_ASSIGNMENT');

  const handleEditVehicleClick = () => {
    onEditVehicleClick?.();
  };

  const {
    vehicle,
    status: vehicleStatus,
    error: vehicleError,
  } = armadaApi.useVehicle(carId);

  return (
    <LoadingErrorEmptyState
      result={vehicle}
      status={vehicleStatus}
      error={vehicleError}
    >
      {(vehicle) => (
        <>
          <header className="vehicle-management__details-header">
            <hgroup className="vehicle-management__details-hgroup">
              <h4 className="vehicle-management__details-title">
                Vehicle information
              </h4>
              <p className="vehicle-management__details-strapline">
                View a vehicles information and edit in a separate view.
              </p>
            </hgroup>

            {canManageVehicle && onEditVehicleClick && (
              <IconButton
                iconName="SquareWithPencil"
                className="vehicle-management__details-action"
                onClick={handleEditVehicleClick}
              />
            )}
          </header>

          <FieldSetWithoutAccessibleLabel vertical>
            {vehicle.vin === vehicle.carId ?
              <TextField
                isStatic
                label="VIN (ID)"
                placeholder=""
                value={vehicle.vin}
              />
            : <>
                <TextField
                  isStatic
                  label="VIN"
                  placeholder=""
                  value={vehicle.vin}
                />

                <TextField
                  isStatic
                  label="ID"
                  placeholder=""
                  value={vehicle.carId}
                />
              </>
            }

            <TextField
              isStatic
              label={t('vehicleRegistration.tableHeaders.carName')}
              placeholder=""
              value={vehicle.carName}
            />

            <TextField
              isStatic
              label="Location"
              placeholder=""
              value={t(`region.${vehicle.region}`) || vehicle.region}
            />

            <TextField
              isStatic
              label="Platform"
              placeholder=""
              value={vehicle.platform}
            />

            <TextField
              isStatic
              label="License plate"
              placeholder=""
              value={vehicle.licensePlate}
            />

            {wantsToManageSweeps && (
              <TextField
                isStatic
                label="Sweeping geonet"
                placeholder="None"
                value={
                  vehicle.geonetSession?.geonetName ?
                    `${vehicle.geonetSession.geonetName} - ${vehicle.geonetSession.state}`
                  : undefined
                }
              />
            )}
          </FieldSetWithoutAccessibleLabel>
        </>
      )}
    </LoadingErrorEmptyState>
  );
}

export default ViewVehicle;
