import clsx from 'clsx';
import { ReactElement, ReactNode, Ref, forwardRef, useMemo } from 'react';
import Icon, { IconName } from 'src/components/common/Icon';
import Button, { ButtonProps, IS_BUTTON_SYMBOL } from './Button';
import './ButtonField.scss';

type FieldButtonSize = 'small' | 'medium' | 'large';

export interface ButtonFieldProps
  extends Omit<ButtonProps, 'label' | 'size' | 'children'> {
  className?: string;
  size?: FieldButtonSize;
  label?: ReactNode;
  endIcon?: IconName | ReactElement;
  startIcon?: IconName | ReactElement;
}

function ButtonField(
  {
    className,
    size = 'large',
    label,
    endIcon,
    startIcon,
    ...buttonProps
  }: ButtonFieldProps,
  ref: Ref<HTMLAnchorElement | HTMLButtonElement>,
) {
  const internals = useMemo(
    () =>
      ['string', 'number'].includes(typeof label) && label !== 'string' ?
        <span className="button-field__label-text">{label}</span>
      : label,
    [label],
  );

  return (
    <Button
      {...buttonProps}
      ref={ref}
      className={clsx([
        className,
        'button-field',
        `button-field--size-${size}`,
      ])}
    >
      {/* TODO: move this across to inGUItiv */}

      <span
        className={clsx([
          'button-field__internals',
          { 'button-field__internals--with-icons': startIcon || endIcon },
        ])}
      >
        {startIcon || endIcon ?
          <>
            {typeof startIcon === 'string' ?
              <Icon name={startIcon} />
            : startIcon}

            {internals && (
              <span className="button-field__custom-content">{internals}</span>
            )}

            {typeof endIcon === 'string' ?
              <Icon name={endIcon} />
            : endIcon}
          </>
        : <>{internals}</>}
      </span>
    </Button>
  );
}

ButtonField.symbol = IS_BUTTON_SYMBOL;

export default forwardRef(ButtonField);
