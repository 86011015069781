import { FleetVehicle } from '@motional-cc/fe/interface/api/armada';
import t from '@motional-cc/fe/tools/translate';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { userApi } from 'src/api/user';
import ButtonField from 'src/components/common/ButtonField';
import FieldSetWithoutAccessibleLabel from 'src/components/common/FieldSetWithoutAccessibleLabel';
import SearchField from 'src/components/common/SearchField';
import { TableSort } from 'src/components/common/Table/interface';
import TableToolBars from 'src/components/common/Table/TableToolBars';
import ToolBar from 'src/components/common/ToolBar';
import VehicleRegistrationColumnPicker from 'src/components/FleetManagement/Jobs/VehicleRegistrationColumnPicker';
import useModal from 'src/components/Modal/modal-hook';
import VehicleList from 'src/components/VehicleRegistration/VehicleList';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { DataLoadStatus } from 'src/interface/command-center/unsorted-types';
import useDebouncedValue from 'src/tools/hooks/useDebouncedValue';
import { useTriggerSearchParam } from 'src/tools/hooks/useTriggerSearchParam';
import transition from 'src/tools/transition';
import CreateVehicle from './CreateVehicle';
import PartnerVehicleForm from './PartnerVehicleForm';
import useVehicleRegistrationVehicles from './useVehicleRegistrationVehicles';
import { PageProps } from './VehicleRegistrationLayout';
import './VehicleListPage.scss';

export const CREATE_MODAL_STATE_PARAM = 'createModalInitialState';

interface Props {
  canFetchMoreVehicles?: boolean;
  canManagePartnerVehicles?: boolean;
  canProvisionVehicles?: boolean;
  errors?: ApiError[];
  fetchMoreVehicles?: () => void;
  isFetchingVehicles?: boolean;
  onSearchChange?: (newValue: string) => void;
  onSort?: (sort?: TableSort) => void;
  refetch?: () => void;
  searchTerm?: string;
  sort?: TableSort;
  status?: DataLoadStatus;
  vehicles?: FleetVehicle[];
}

export function VehicleListPage({
  canFetchMoreVehicles,
  canManagePartnerVehicles,
  canProvisionVehicles,
  errors,
  fetchMoreVehicles,
  isFetchingVehicles,
  onSearchChange,
  onSort = () => null,
  refetch,
  searchTerm,
  sort,
  status,
  vehicles,
}: Props) {
  const {
    openModal: openNewVehicleModal,
    ModalComponent: NewVehicleModal,
    closeModal: closeNewVehicleModal,
  } = useModal();

  const {
    openModal: openNewPartnerVehicleModal,
    ModalComponent: NewPartnerVehicleModal,
    closeModal: closeNewPartnerVehicleModal,
  } = useModal();

  useTriggerSearchParam({
    param: CREATE_MODAL_STATE_PARAM,
    triggerValue: 'open',
    triggerFunction: () => {
      openNewVehicleModal();
    },
  });

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);
  const finalisedSearchTerm =
    debouncedSearchTerm && searchTerm === debouncedSearchTerm ?
      debouncedSearchTerm
    : '';

  const handleClickNewPartnerVehicle = () => {
    openNewPartnerVehicleModal();
  };
  const handleClickNewPartnerVehicleClose = () => {
    closeNewPartnerVehicleModal();
  };
  const handleClickNewPartnerVehicleSave = () => {
    closeNewPartnerVehicleModal();
    refetch?.();
  };
  const handleClickNewVehicle = () => {
    openNewVehicleModal();
  };
  const handleClickNewVehicleClose = () => {
    closeNewVehicleModal();
  };
  const handleClickNewVehicleSave = () => {
    closeNewVehicleModal();
    refetch?.();
  };

  return (
    <motion.div
      {...transition({
        x: -500,
      })}
    >
      {(canManagePartnerVehicles || canProvisionVehicles) && (
        <div className="the-page__container the-page__container--padded-on-mobile">
          <FieldSetWithoutAccessibleLabel
            horizontal
            end
            className="the-page__list-actions"
          >
            {canManagePartnerVehicles && (
              <ButtonField
                className="the-page__list-action"
                onClick={handleClickNewPartnerVehicle}
                label={t('vehicleRegistration.createPartnerVehicle')}
                color="call-to-action"
              />
            )}

            {canProvisionVehicles && (
              <ButtonField
                className="the-page__list-action"
                onClick={handleClickNewVehicle}
                label={t('vehicleRegistration.createVehicle')}
                color="call-to-action"
              />
            )}
          </FieldSetWithoutAccessibleLabel>
        </div>
      )}

      <div className="the-page__container">
        <div className="the-page__list-container">
          <VehicleList
            className="the-page__list-table"
            vehicles={vehicles}
            vehiclesAreLoading={isFetchingVehicles}
            onSort={onSort}
            sort={sort}
            onVehicleUpdate={refetch}
            status={status}
            error={errors?.[0]}
            canFetchMoreVehicles={canFetchMoreVehicles}
            fetchMoreVehicles={fetchMoreVehicles}
            searchTerm={
              debouncedSearchTerm && debouncedSearchTerm.length > 2 ?
                debouncedSearchTerm
              : ''
            }
            header={
              <TableToolBars>
                <ToolBar>
                  <SearchField
                    showErrorMessage
                    className="the-page__search-bar"
                    onChange={onSearchChange}
                    value={searchTerm}
                    label={t('vehicleRegistration.listPage.searchLabel')}
                    placeholder={t(
                      'vehicleRegistration.listPage.searchPlaceholder',
                    )}
                    errorMessage={
                      !finalisedSearchTerm || finalisedSearchTerm.length > 2 ?
                        undefined
                      : 'Search term must be longer than 2 characters'
                    }
                  />

                  <VehicleRegistrationColumnPicker />
                </ToolBar>
              </TableToolBars>
            }
          />
        </div>
      </div>

      <NewPartnerVehicleModal
        title={t('vehicleRegistration.createPartnerVehicle')}
      >
        <PartnerVehicleForm
          onCancelClick={handleClickNewPartnerVehicleClose}
          onSaveSuccess={handleClickNewPartnerVehicleSave}
        />
      </NewPartnerVehicleModal>

      <NewVehicleModal title={t('vehicleRegistration.createVehicle')}>
        <CreateVehicle
          onCancelClick={handleClickNewVehicleClose}
          onSaveSuccess={handleClickNewVehicleSave}
        />
      </NewVehicleModal>
    </motion.div>
  );
}

/* istanbul ignore next */
function VehicleListPageConnected() {
  const canProvisionVehicles = userApi.useHasScopes(['manage:provision']);
  const canManagePartnerVehicles = userApi.useHasScopes([
    'manage:partner-vehicle-data',
  ]);
  const [sort, setSort] = useState<TableSort>();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    vehicles,
    isFetchingVehicles,
    status,
    errors,
    canFetchMoreVehicles,
    fetchMoreVehicles,
    refetch,
  } = useVehicleRegistrationVehicles({
    sort,
    searchTerm,
  });

  return (
    <VehicleListPage
      vehicles={vehicles}
      isFetchingVehicles={isFetchingVehicles}
      status={status}
      errors={errors ?? undefined}
      canFetchMoreVehicles={canFetchMoreVehicles ?? false}
      canProvisionVehicles={canProvisionVehicles ?? undefined}
      canManagePartnerVehicles={canManagePartnerVehicles ?? undefined}
      onSort={setSort}
      sort={sort}
      fetchMoreVehicles={fetchMoreVehicles}
      refetch={refetch}
      onSearchChange={setSearchTerm}
      searchTerm={searchTerm}
    />
  );
}

export const pageProps: PageProps = {
  title: 'Vehicle registration',
  openTab: 'list',
};
export default VehicleListPageConnected;
