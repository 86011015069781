import { VehicleResponse } from '@motional-cc/fe/interface/api/api-concierge';
import {
  GeonetConstraint,
  Geonets,
} from '@motional-cc/fe/interface/api/api-server';
import {
  Enroll,
  ModernModel,
  Vehicle as NurseryVehicle,
} from '@motional-cc/fe/interface/api/nursery';
import {
  Model,
  Platform,
  Vehicles as RegistrarVehicles,
} from '@motional-cc/fe/interface/api/registrar';
import t from '@motional-cc/fe/tools/translate';
import { useMemo, useState } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { useMutateApi } from 'src/api/hooks/service';
import { userApi } from 'src/api/user';
import ButtonField from 'src/components/common/ButtonField';
import FieldSet from 'src/components/common/FieldSet';
import Select from 'src/components/common/Select/Select';
import SelectEntity from 'src/components/common/Select/SelectEntity';
import SelectPlatform from 'src/components/common/Select/SelectPlatform';
import TextField from 'src/components/common/TextField';
import VerticalForm from 'src/components/common/VerticalForm';
import { useMessages } from 'src/components/Messages/messages-context';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import {
  MODERN_CAR_MODEL_NAMES,
  REGIONS,
} from 'src/interface/command-center/unsorted-type-arrays';
import { useHasToggle } from 'src/tools/hooks/useHasToggle';
import SelectVehicleModel from './SelectVehicleModel';
import { vehicleVinRegexp } from './utils';
import './VehicleForm.scss';

// G2: Generation
// H: Manufacturer
// [A-Z]{2}: Model
// [A-Z]: Phase
// \d: Last 5 VIN digits
// Or VIN: [A-Z\d]{12}\d{5}
const ioniqIdRegExp = /^(G2H[A-Z]{2}[A-Z]\d{5}|[A-Z\d]{12}\d{5})$/;
// motional_12345
const nonIoniqIdRegExp = /^((motional|nutonomy|aptiv|aptiv_lyft)_\d{5})$/;

const isModernModel = (model: Model | undefined): model is ModernModel =>
  !!model && MODERN_CAR_MODEL_NAMES.includes(model as ModernModel);

type CreateFields = Partial<Enroll.EnrollVehicle.RequestBody>;

export type EditFields = Pick<
  VehicleResponse,
  'geonetSession' | 'currentTrip'
> &
  NurseryVehicle.UpdateVehicle.RequestBody &
  Partial<
    Omit<
      RegistrarVehicles.GetVehicles.ResponseBody,
      keyof NurseryVehicle.UpdateVehicle.RequestBody
    >
  >;

type Fields<Mode extends 'create' | 'edit'> =
  Mode extends 'create' ? CreateFields : EditFields;

const isEditFields = (
  fields: CreateFields | EditFields | undefined,
): fields is EditFields => {
  return (fields as EditFields | undefined)?.carId !== undefined;
};

function formIsValid<EditMode extends boolean>(
  value: EditMode extends false ? CreateFields : EditFields,
): value is Enroll.EnrollVehicle.RequestBody {
  const hasUneditableValues =
    isEditFields(value) ||
    (value.otp && value.vin && value.car_id && value.model);
  return !!(
    hasUneditableValues &&
    value.alias &&
    value.license_plate &&
    value.platform &&
    value.region
  );
}

const renderRegion = (region: string) => t(`region.${region}`) || region;

type Props<Mode extends 'create' | 'edit'> = {
  initialValue: Fields<Mode>;
  onSubmit: (value: Enroll.EnrollVehicle.RequestBody) => void;
  onCancel?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
  error?: string;
  mode: Mode;
  disabled?: boolean;
};

function VehicleForm<Mode extends 'create' | 'edit'>({
  initialValue,
  onSubmit,
  onCancel,
  onSuccess,
  onFail,
  error,
  mode,
  disabled: disabledProps = false,
}: Props<Mode>) {
  const { showMessage } = useMessages();

  const isAllowedToManageSweeps = userApi.useHasScopes([
    'manage:geonet-sessions',
  ]);
  const wantsToManageSweeps = useHasToggle('ENABLE_GEONET_ASSIGNMENT');
  const canManageSweeps = isAllowedToManageSweeps && wantsToManageSweeps;

  const isEditMode = mode === 'edit';
  const modelList = useMemo(
    () =>
      !isEditMode ? MODERN_CAR_MODEL_NAMES
      : initialValue.model ? [initialValue.model]
      : [],
    [isEditMode, initialValue.model],
  );
  const [submitWasAttempted, setSubmitWasAttempted] = useState(false);
  const [value, setValue] = useState<Fields<Mode>>(initialValue);

  const { mutate: startGeonetSession, isLoading: isStartingSession } =
    useMutateApi<
      Geonets.CreateGeonetSessions.ResponseBody,
      Geonets.CreateGeonetSessions.RequestBody
    >(apiServerPaths.Geonets.CreateGeonetSessions(), 'POST');

  const { mutate: endGeonetSession, isLoading: isEndingSession } = useMutateApi<
    Geonets.DeleteGeonetSessions.ResponseBody,
    Geonets.DeleteGeonetSessions.RequestBody
  >(
    apiServerPaths.Geonets.DeleteGeonetSessions(
      isEditFields(value) ? value.geonetSession?.sessionId || '' : '',
    ),
    'DELETE',
  );

  const disabled = disabledProps || isStartingSession || isEndingSession;

  const cannotAssignSweepGeonetReason =
    !isEditFields(value) ? 'Cannot edit sweep in create mode'
    : value.geonetSession?.geonetName ? 'Sweep is already assigned'
    : isStartingSession ? 'Starting new session…'
    : isEndingSession ? 'Ending ongoing session…'
    : undefined;

  const cannotEndSweepReason =
    !isEditFields(value) ? 'Cannot end sweep in create mode'
    : value.currentTrip && !value.currentTrip.initiator ?
      'Cannot end sweep while trip initiator is unknown'
    : value.currentTrip?.initiator === 'GeonetSweep' ?
      'Sweep must be paused before being ended'
    : isStartingSession ? 'Starting new session…'
    : isEndingSession ? 'Ending ongoing session…'
    : undefined;

  const handleOnChange =
    (key: keyof Enroll.EnrollVehicle.RequestBody) => (newValue: string) => {
      setValue((oldFields) => ({
        ...oldFields,
        [key]: newValue,
      }));
    };

  const handleFormSubmit = () => {
    if (!formIsValid(value)) {
      setSubmitWasAttempted(true);
      return;
    }

    onSubmit(value);
  };

  const handleGeonetSelect = async (sweepGeonet: Partial<GeonetConstraint>) => {
    if (
      cannotAssignSweepGeonetReason ||
      !isEditFields(value) ||
      !sweepGeonet?.id ||
      !value.carId
    ) {
      return;
    }

    try {
      await startGeonetSession({
        geonet_id: sweepGeonet?.id,
        vehicle_id: value.carId,
      });
      onSuccess?.();
      showMessage({
        type: 'success',
        title: `Assigned geonet ${sweepGeonet.name}`,
      });
    } catch (_error) {
      const error = _error as ApiError;

      onFail?.();
      showMessage({
        type: 'error',
        title: `An error occurred while assigning geonet ${sweepGeonet.name}`,
        description: error.message,
      });
    }
  };

  const handleEndSweepClick = async () => {
    if (cannotEndSweepReason || !isEditFields(value)) return;

    try {
      await endGeonetSession();
      onCancel?.();
      showMessage({
        type: 'success',
        title: `Unassigned geonet ${value.geonetSession?.geonetName}`,
      });
    } catch (_error) {
      const error = _error as ApiError;

      onFail?.();
      showMessage({
        type: 'error',
        title: `An error occurred while unassigning ${value.geonetSession?.geonetName}`,
        description: error.message,
      });
    }
  };

  return (
    <VerticalForm
      className="vehicle-form"
      onSubmit={handleFormSubmit}
      onSubmitText="Save"
      onCancel={onCancel}
      isSubmitting={disabled || isStartingSession || isEndingSession}
      isValid={formIsValid(value)}
    >
      <FieldSet
        vertical
        hideLabel
        label="Provision vehicle"
        className="vehicle-form__fields"
      >
        <SelectVehicleModel
          autoFocus
          className="vehicle-form__model"
          value={value.model}
          onChange={handleOnChange('model')}
          isStatic={isEditMode}
          disabled={disabled || isEditMode}
          models={modelList}
        />

        {!isEditFields(value) ?
          <>
            <TextField
              key="vehicle-form__vin"
              required
              disabled={disabled}
              showErrorMessage={submitWasAttempted}
              label="VIN"
              className="vehicle-form__vin"
              placeholder="WBAJE7C12JWC34567"
              value={value.vin}
              onChange={handleOnChange('vin')}
              pattern={vehicleVinRegexp}
            />

            <TextField
              key="vehicle-form__id"
              required
              disabled={disabled}
              showErrorMessage={submitWasAttempted}
              label="ID"
              className="vehicle-form__id"
              value={value.car_id}
              onChange={handleOnChange('car_id')}
              placeholder={
                value.model === 'Ioniq' || value.model === 'Eval' ?
                  'G2HNEA12345 / VIN'
                : 'motional_12345'
              }
              pattern={
                value.model === 'Ioniq' || value.model === 'Eval' ?
                  ioniqIdRegExp
                : nonIoniqIdRegExp
              }
            />
          </>
        : value.vin === value.carId ?
          <TextField
            key="vehicle-form__vin"
            isStatic
            label="VIN (ID)"
            className="vehicle-form__vin"
            placeholder="No VIN"
            value={value.vin}
          />
        : <>
            <TextField
              key="vehicle-form__vin"
              isStatic
              label="VIN"
              className="vehicle-form__vin"
              placeholder="No VIN"
              value={value.vin}
            />
            <TextField
              key="vehicle-form__id"
              isStatic
              label="ID"
              className="vehicle-form__id"
              value={value.carId}
              placeholder="No ID"
            />
          </>
        }

        <TextField
          required
          disabled={disabled}
          showErrorMessage={submitWasAttempted}
          label={t('vehicleRegistration.tableHeaders.carName')}
          className="vehicle-form__alias"
          value={value.alias}
          placeholder={`${value.model} 1`}
          onChange={handleOnChange('alias')}
          autoFocus={isEditMode}
        />

        <Select
          required
          className="vehicle-form__region"
          disabled={disabled}
          showErrorMessage={submitWasAttempted ?? false}
          label="Location"
          options={REGIONS}
          value={value.region}
          renderSelectedOption={renderRegion}
          renderListOption={renderRegion}
          onSelect={handleOnChange('region')}
          spacing="comfortable"
        />

        <SelectPlatform
          required
          className="vehicle-form__platform"
          showErrorMessage={submitWasAttempted ?? false}
          label="Platform"
          value={value.platform}
          disabled={disabled}
          model={isModernModel(value.model) ? value.model : undefined}
          onSelect={handleOnChange('platform') as (newValue?: Platform) => void}
        />

        <TextField
          required
          disabled={disabled}
          showErrorMessage={submitWasAttempted}
          label="License plate"
          className="vehicle-form__license-plate"
          value={value.license_plate}
          placeholder="RD1234X"
          onChange={handleOnChange('license_plate')}
        />

        {!isEditFields(value) && (
          <TextField
            required
            disabled={disabled}
            showErrorMessage={submitWasAttempted}
            label="One time password"
            className="vehicle-form__otp"
            value={value.otp}
            placeholder="123456"
            onChange={handleOnChange('otp')}
          />
        )}

        {error && <div className="vehicle-form__error">{error}</div>}
      </FieldSet>

      {isEditFields(value) && canManageSweeps && (
        <FieldSet horizontal hideLabel label="Provision vehicle">
          <SelectEntity<
            Geonets.GetGeonetConstraints.ResponseBody,
            Partial<GeonetConstraint>
          >
            allowClear
            label="Geonet"
            placeholder="Geonet to sweep"
            optionKeyPath="id"
            url={apiServerPaths.Geonets.GetGeonetConstraints()}
            renderListOption={({ name }) => name}
            renderSelectedOption={({ name }) => name}
            onSelect={handleGeonetSelect}
            disabled={!!cannotAssignSweepGeonetReason}
            params={{
              regions: value.region,
            }}
            value={
              value.geonetSession?.geonetName ?
                {
                  name: value.geonetSession?.geonetName,
                }
              : undefined
            }
          />

          {value.geonetSession?.geonetName && (
            <ButtonField
              allowInteractionWhenDisabled
              className="vehicle-form__end-session-button"
              startIcon="OctagonWithHandOpen"
              color="destructive"
              onClick={handleEndSweepClick}
              disabled={!!cannotEndSweepReason}
            />
          )}
        </FieldSet>
      )}
    </VerticalForm>
  );
}

export default VehicleForm;
