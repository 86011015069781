import { useEffect, useMemo } from 'react';
import usePrevious from 'src/tools/hooks/usePrevious';

export default function useOnChange<Value>(
  currentValue: Value,
  onChange: (previousValue?: Value, currentValue?: Value) => void,
) {
  const previousValue = usePrevious(currentValue);

  useEffect(
    function runCallbackWhenValueChanges() {
      if (previousValue === currentValue) return;

      onChange(previousValue, currentValue);
    },
    [currentValue, onChange, previousValue],
  );

  return useMemo(
    () => ({
      previousValue,
      currentValue,
      updatedThisRender: previousValue !== currentValue,
    }),
    [currentValue, previousValue],
  );
}
