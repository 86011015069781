import {
  Vehicle,
  Vehicles,
  urls,
} from '@motional-cc/fe/interface/api/registrar';
import { registrar as registrarHost } from 'src/api/hosts';
import { RefetchInterval, useApi } from './hooks/service';
import { userApi } from './user';
import { convertToAbsolutePaths } from './utils';

export const registrarPaths = convertToAbsolutePaths(registrarHost, urls);

const useVehicle = (
  carId: string | undefined,
  {
    refetchInterval,
    enabled = true,
  }: { refetchInterval?: RefetchInterval; enabled?: boolean } = {},
) => {
  const canReadRegistrarVehicles = userApi.useHasScopes(['read:manifests']);
  const { result: vehiclePayload, ...rest } =
    useApi<Vehicles.GetVehicles.ResponseBody>(
      registrarPaths.Vehicles.GetVehicles(carId ?? ''),
      {
        enabled: enabled && !!canReadRegistrarVehicles && !!carId,
        refetchInterval: refetchInterval ?? undefined,
        knownErrorCodes: ['not_found'],
      },
    );

  return {
    // TODO: why does this show up as BundlelessVehicle without the `as Vehicle` cast
    vehicle: ((enabled && vehiclePayload) || null) as Vehicle | null,
    hasPermission: canReadRegistrarVehicles,
    ...rest,
  };
};

export const registrarApi = {
  useVehicle,
};
