import { SummaryState } from '@motional-cc/fe/interface/api/armada';
import { Vehicle as NurseryVehicle } from '@motional-cc/fe/interface/api/nursery';
import { Vehicles } from '@motional-cc/fe/interface/api/vehicle-status-service';
import t from '@motional-cc/fe/tools/translate';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { armadaApi, armadaPaths } from 'src/api/armada';
import { conciergePaths } from 'src/api/concierge';
import { useMutateApi } from 'src/api/hooks/service';
import { nurseryPaths } from 'src/api/nursery';
import { registrarPaths } from 'src/api/registrar';
import { userApi } from 'src/api/user';
import ButtonField from 'src/components/common/ButtonField';
import Checkbox from 'src/components/common/Checkbox';
import FieldSetWithoutAccessibleLabel from 'src/components/common/FieldSetWithoutAccessibleLabel';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import Tooltip from 'src/components/common/Tooltip';
import useDialog from 'src/components/Dialog/dialog-hook';
import { useMessages } from 'src/components/Messages/messages-context';
import VehicleStatusDot from 'src/components/Vehicles/VehicleStatusDot';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { isRide } from 'src/tools/vehicle/isRide';
import VehicleForm, { EditFields } from './VehicleForm';
import './EditVehicle.scss';

interface Props {
  carId?: string;
  onSaveError?: () => void;
  onSaveSuccess?: () => void;
  onDeleteSuccess?: () => void;
  onCancelClick?: () => void;
}

function EditVehicle({
  carId,
  onSaveError,
  onSaveSuccess,
  onDeleteSuccess,
  onCancelClick,
}: Props) {
  const canDeleteVehicles = userApi.useHasScopes(['manage:remove_vehicle']);
  const { DialogComponent: DeleteDialog, openDialog: openDeleteDialog } =
    useDialog();
  const queryClient = useQueryClient();
  const { showMessage } = useMessages();

  const [affirmedVehicleIsDeletionSafe, setAffirmedVehicleIsDeletionSafe] =
    useState(false);

  const {
    vehicle,
    error: vehicleError,
    refetch: refetchVehicle,
  } = armadaApi.useVehicle(carId ?? '', { enabled: !!carId });

  const {
    mutate: updateVehicle,
    error: updateVehicleError,
    isLoading: isUpdating,
  } = useMutateApi<
    NurseryVehicle.UpdateVehicle.ResponseBody,
    NurseryVehicle.UpdateVehicle.RequestBody
  >(nurseryPaths.Vehicle.UpdateVehicle(carId ?? ''), 'PATCH');

  const {
    mutate: deleteVehicle,
    error: deleteError,
    response: deleteSuccess,
    isLoading: isDeleting,
  } = useMutateApi<
    NurseryVehicle.DeleteVehicle.ResponseBody,
    NurseryVehicle.DeleteVehicle.RequestBody
  >(nurseryPaths.Vehicle.DeleteVehicle(carId ?? ''), 'DELETE');

  const isLoading = isUpdating || isDeleting;

  const displayName = vehicle?.carName || carId;

  const refetchEverything = useCallback(() => {
    refetchVehicle();
    queryClient.invalidateQueries({
      queryKey: [registrarPaths.Vehicles.GetVehicles(carId ?? '')],
    });
    queryClient.invalidateQueries({
      queryKey: [conciergePaths.Vehicles.VehiclesDetail(carId ?? '')],
    });
    // This will invalidate all fleet caches
    queryClient.invalidateQueries({
      queryKey: [armadaPaths.Fleets.VehiclesDetail('||||').split('||||')[0]],
    });
    queryClient.invalidateQueries({
      queryKey: [armadaPaths.Vehicles.VehiclesList()],
    });
  }, [carId, queryClient, refetchVehicle]);

  useEffect(
    function displayEditVehicleError() {
      if (vehicleError) {
        showMessage({
          type: 'warning',
          title: `${displayName} can not be edited`,
          description:
            'This usually happens when a vehicle has been deleted or corrupted. Please refresh the page or contact support for more information.',
        });
        onSaveError?.();
      }
    },
    [vehicleError, displayName, showMessage, onSaveError],
  );

  useEffect(
    function displayDeleteVehicleError() {
      if (deleteError) {
        showMessage({
          type: 'warning',
          title: `Failed to delete ${displayName}`,
        });
      }
    },
    [deleteError, displayName, showMessage],
  );

  useEffect(
    function handleDeleteSuccess() {
      if (!deleteSuccess) return;

      refetchEverything();

      showMessage({
        type: 'success',
        title: `${displayName} has been deleted successfully`,
      });

      onSaveSuccess?.();
      onDeleteSuccess?.();
    },
    [
      carId,
      deleteSuccess,
      displayName,
      onSaveSuccess,
      onDeleteSuccess,
      showMessage,
      queryClient,
      refetchEverything,
    ],
  );

  const summaryState: SummaryState = vehicle?.summaryState || 'UNKNOWN';

  // Mimic back-end logic
  // https://gitlab.ci.motional.com/rti/FAS/-/blob/a5f4b8c1e10c3d179372821775ec2ff6e8e04be1/api-server/internal/pokedex/services/partner_vehicle_mapping.go#L140-145
  const isReadyForRide =
    vehicle?.connected &&
    vehicle.dispatchMode &&
    vehicle.availabilityState === 'Available';
  const vehicleCannotBeEdited = isReadyForRide || isRide(vehicle?.currentTrip);

  const updatePayload: EditFields | undefined = useMemo(
    () =>
      vehicle ?
        {
          alias: vehicle.carName,
          carId: vehicle.carId,
          license_plate: vehicle.licensePlate,
          model: vehicle.model,
          platform: vehicle.platform,
          region: vehicle.region,
          vin: vehicle.vin,
          geonetSession: vehicle.geonetSession,
          currentTrip: vehicle.currentTrip,
        }
      : undefined,
    [vehicle],
  );

  const handleCancelAndSuccess = () => {
    refetchEverything();
    onCancelClick?.();
  };

  const handleFail = () => {
    refetchEverything();
    onCancelClick?.();
  };

  const handleSubmit = async ({
    alias,
    platform,
    license_plate,
    region,
  }: NurseryVehicle.UpdateVehicle.RequestBody) => {
    const requests: Promise<
      | NurseryVehicle.UpdateVehicle.ResponseBody
      | Vehicles.UpdateVehiclePartners.ResponseBody
      | void
      | undefined
    >[] = [];

    if (
      !vehicle ||
      !isEqual(
        {
          alias: vehicle?.carName,
          platform: vehicle?.platform,
          license_plate: vehicle?.licensePlate,
          region: vehicle?.region,
        },
        { alias, platform, license_plate, region },
      )
    ) {
      requests.push(
        updateVehicle({
          alias,
          platform,
          license_plate,
          region,
        }),
      );
    }

    try {
      await Promise.all(requests);

      refetchEverything();

      showMessage({
        type: 'success',
        title: `${displayName} has been updated successfully`,
        description: 'You may need to refresh to see the changes',
      });

      onSaveSuccess?.();
    } catch (_error) {
      const error = _error as ApiError;
      showMessage({
        type: 'error',
        title: `${displayName} couldn’t be updated`,
        description: error.message,
      });
    }
  };

  const handleDeleteClick = () => {
    openDeleteDialog();
  };

  const handleConfirmDeleteClick = () => {
    if (!affirmedVehicleIsDeletionSafe) return;

    deleteVehicle();
  };

  return (
    <>
      <div className="edit-vehicle">
        {vehicleCannotBeEdited && (
          <div className="edit-vehicle__vehicle-summary-note">
            <Tooltip
              content={`Current state: ${
                t(`vehicleSummaryState.label.${summaryState}`) || summaryState
              }`}
            >
              <VehicleStatusDot
                summaryState={summaryState}
                className="edit-vehicle__vehicle-summary-state"
              />
            </Tooltip>

            <div className="edit-vehicle__vehicle-is-active-text">
              <p>
                Vehicle cannot be edited while it is active.
                <br />
                Stop accepting rides and finish any pending rides to continue.
              </p>
            </div>
          </div>
        )}

        {updatePayload ?
          <VehicleForm
            initialValue={updatePayload}
            onSubmit={handleSubmit}
            onCancel={handleCancelAndSuccess}
            onSuccess={handleCancelAndSuccess}
            onFail={handleFail}
            error={updateVehicleError?.message}
            mode="edit"
            disabled={vehicleCannotBeEdited || isLoading}
          />
        : <FullWidthLoader />}

        {canDeleteVehicles && (
          <FieldSetWithoutAccessibleLabel horizontal fill>
            <ButtonField
              disabled={isDeleting || !!deleteSuccess}
              onClick={handleDeleteClick}
              label="Delete"
              color="text-destructive"
              size="medium"
              className={clsx([
                'vehicle-form__action',
                'vehicle-form__action--delete',
              ])}
            />
          </FieldSetWithoutAccessibleLabel>
        )}
      </div>

      <DeleteDialog
        title={`Delete vehicle: ${displayName}`}
        warningText={`Delete ${displayName}`}
        onWarningClick={handleConfirmDeleteClick}
        warningDisabled={!affirmedVehicleIsDeletionSafe}
        description={
          <>
            <p className="dialog-box__description">
              This cannot be undone.
              <br />
              All of the vehicle’s history will be lost.
            </p>
            <p className="dialog-box__description">
              Ensure the vehicle is in a safe state, as this action will remove
              all ability for the vehicle to be communicated with by Command
              Center
            </p>

            <Checkbox
              label="The vehicle is in a safe state to be deleted from Command Center"
              checked={affirmedVehicleIsDeletionSafe}
              onChange={setAffirmedVehicleIsDeletionSafe}
            />
          </>
        }
      />
    </>
  );
}

export default EditVehicle;
