import find from 'lodash/find';
import get from 'lodash/get';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { WrappedResult, useInfiniteApi } from 'src/api/hooks/service';
import {
  CommonProps,
  SecondaryActionProps,
} from 'src/components/common/Select/interface';
import { AllOrNoneOf, QueryParams } from 'src/interface/utility';
import Select from './Select';
import './SelectEntity.scss';

type Props<Option, Value = Option> = Omit<
  CommonProps<Option>,
  'options' | 'onScrollToBottom'
> &
  AllOrNoneOf<SecondaryActionProps> & {
    url: string;
    optionLabelPath?: string;
    optionValuePath?: string;
    value?: Value;
    className?: string;
    onSelect: (newValue?: Value) => void;
    params?: Readonly<Partial<QueryParams>> | undefined;
    unsearchable?: boolean;
    useLocalSearch?: boolean;
    emptyPlaceholder?: string;
    paginated?: boolean;
    pageSize?: number;
    optionFilter?: (option: Option) => boolean;
    autoSelectSingleOption?: boolean;
  };

// TODO: find a better way to deal with ValueType after TS 4.1

function SelectEntity<
  ResponseType extends WrappedResult,
  Value = ResponseType,
>({
  url,
  optionKeyPath = '',
  optionLabelPath: optionLabelKey = optionKeyPath,
  renderSelectedOption = (option: Value) => get(option, optionLabelKey),
  renderListOption,
  optionValuePath,
  value: propsValue,
  className,
  onSelect,
  disabled,
  params: paramsProps,
  optionFilter,
  unsearchable = false,
  useLocalSearch = false,
  paginated = true,
  pageSize = paginated ? 20 : undefined,
  autoSelectSingleOption,
  emptyPlaceholder,
  ...selectProps
}: Props<ResponseType['result'][number], Value>) {
  const [searchText, setSearchText] = useState<string | undefined>();

  const params = useMemo(() => {
    const params = paramsProps ? { ...paramsProps } : {};

    if (!useLocalSearch) {
      params.q = searchText;
    }

    return params;
  }, [paramsProps, searchText, useLocalSearch]);

  const {
    result: apiOptions,
    fetchMore,
    canFetchMore,
    isFetching,
    error,
  } = useInfiniteApi<ResponseType>(url, {
    enabled: !disabled,
    isKnownToBeAnIrregularApi: !paginated,
    pageSize,
    params,
  });

  const options = useMemo(() => {
    if (!optionFilter) {
      return apiOptions;
    }

    return apiOptions?.filter(optionFilter);
  }, [apiOptions, optionFilter]);

  const value = useMemo(() => {
    // we need to get the actual value using the path provided
    return optionValuePath ?
        find(options, [optionValuePath, propsValue])
      : (propsValue as Value);
  }, [optionValuePath, options, propsValue]);

  const filteredOptions = useMemo(() => {
    if (!useLocalSearch || !searchText) {
      return options;
    }

    return options?.filter((option) => {
      const label = renderSelectedOption(option);
      return label.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [options, renderSelectedOption, searchText, useLocalSearch]);

  const handleOnSelect = useCallback(
    (option: Value) => {
      if (optionValuePath) {
        onSelect(get(option, optionValuePath));
      } else {
        // if we don't have optionValuePath we use the value directly
        onSelect(option);
      }
    },
    [optionValuePath, onSelect],
  );

  useEffect(
    function selectOptionIfOnlyOne() {
      if (!autoSelectSingleOption || apiOptions?.length !== 1 || value) {
        return;
      }

      handleOnSelect(apiOptions[0]);
    },
    [value, apiOptions, autoSelectSingleOption, handleOnSelect],
  );

  const handleScrollToBottom = () => {
    if (isFetching) return;

    fetchMore();
  };

  const placeholder =
    disabled || options?.length ? selectProps.placeholder ?? 'Select'
    : isFetching ? 'Loading…'
    : error ? 'Error'
    : emptyPlaceholder ?? 'No options';

  const sharedProps: Exclude<
    Parameters<typeof Select<Value>>[0],
    'searchText' | 'onSearchTextChange'
  > = {
    ...selectProps,
    className: className,
    value,
    onSelect: handleOnSelect,
    renderSelectedOption,
    renderListOption,
    optionKeyPath,
    options: filteredOptions || [],
    onScrollToBottom:
      paginated && canFetchMore ? handleScrollToBottom : undefined,
    disabled: disabled || (!options?.length && !value),
    placeholder,
  };

  return unsearchable ?
      <Select<Value> {...sharedProps} />
    : <Select<Value>
        {...sharedProps}
        searchText={searchText ?? ''}
        onSearchTextChange={setSearchText}
      />;
}

export default SelectEntity;
